@import '../node_modules/bulma/sass/utilities/initial-variables.sass';
@import '../node_modules/bulma/sass/utilities/mixins.sass';
@import 'colors.scss';

@import '../node_modules/bulma/bulma.sass';

@font-face {
    font-family: NewAcademy;
    src: url('../public/font/new-academy.ttf')
}

@font-face {
    font-family: OpenSans;
    src: url('../public/font/OpenSans.ttf');
}

@font-face {
    font-family: OpenSansItalic;
    src: url('../public/font/OpenSansItalic.ttf');
}

html {
  overflow-y: auto;
}

header.navbar {
  .navbar-brand, .navbar-brand a {
    font-family: NewAcademy, 'Time New Roman', serif;
    font-size: 2em;
    font-weight: bold;
  }
  .navbar-brand > .navbar-item {
    align-items: baseline;
  }
}

.panel.is-info .panel-tabs a.is-active {
    background-color: $primary;
    border-color: $primary;
}

.panel-block > div {
    width: 100%;
}

#main {
  font-family: OpenSans;
  > aside.menu {
    order: -1;
  }
}

.field-label {
    flex-grow: 2;
}

.vod-wrapper {
    display: flex;
    overflow: auto;
    max-height: 100vh;
    @include from($desktop) {
        flex-direction: row;
        align-items: stretch;
    }
    @include until($desktop) {
        flex-direction: column;
        align-items: stretch;
    }
}

.has-video {
    @include from($desktop) {
        width: 75%;
    }
}

.has-chat {
    overflow: auto;
    @include from($desktop) {
        width: 25%;
        padding: 0 1rem;
    }
    @include until($desktop) {
        // max-height: 10vh;
    }
}

#chat {
    // height: 100%;
    overflow-wrap: break-word;
    hypens: manual;

    @include until($desktop) {
        padding: 0.2rem 1rem;
    }
}

.chat-msg {
    &.USERNOTICE {
        border-left: solid 2px #9146FF;
        padding-left: 5px;
    }

    .badge {
        margin-right: 2px;
    }

    .msg-time {
        color: #888;
        font-size: 0.8em;
        margin-right: 0.5em;
    }

    a {
        color: $blue;
    }
}

iframe#player {
    aspect-ratio: 16/9;
    width:100%;
    height: auto;
}

.is-overflow-y {
    overflow-y: auto;
    scrollbar-color: #333 #666;
    scrollbar-width: thin;
    &.with-scrollbar {
        scrollbar-width: thin;
    }
}

@media screen and (min-width: 769px), print {
    .column.has-chat {
        max-width: 30%;
    }
}
